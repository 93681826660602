<template>
  <div class="ui grid container-3">
    <div class="content-details">
      <h1>Benefits<span> of Becoming a Partner or Merchant</span></h1>
      <div class="ui grid two column row stackable">
        <div class="column">
          <div class="item">
            <h4>Becoming a Partner or Merchant is <span>free</span></h4>
            <p>No sign-up fees when you enroll as a partner or merchant.</p>
          </div>
          <div class="item">
            <h4>Manage your <span>time</span> and transactions</h4>
            <p>Do business on your own time by setting limits to decide when you want to accept transaction requests.</p>
          </div>
          <div class="item">
            <h4>Earn <span>extra</span> income</h4>
            <p>Earn a percentage of each transaction from TayoCash services you provide such as cash in, cash out, bills payment, selling load, and many more.</p>
          </div>
        </div>
        <div class="column">
          <div class="item">
            <h4>Join exclusive Partner or Merchant <span>promos</span></h4>
            <p>Get special privileges with extra incentives and additional perks.</p>
          </div>
          <div class="item">
            <h4>Get free <span>marketing support</span> & more customers</h4>
            <p>Be promoted and offer exclusive promos to TayoCash members via our app, social media pages, email newsletters, and SMS.</p>
          </div>
          <div class="item">
            <h4>Provide better <span>customer service</span></h4>
            <p>Provide your customers with an easier and safer payment option through our Pay QR feature. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content3'
}
</script>

<style scoped lang='scss'></style>
